.login-reg {
  margin-top: -60px;
}

.login-reg .single-input {
  margin-bottom: 30px;
}

.login-reg .single-input label {
  font-weight: 600;
}

.login-reg a,
.login-reg p {
  font-weight: 600;
}

.login-reg .dont-acc a {
  color: var(--primary-color);
}

.login-reg .reg-google {
  margin-top: 30px;
}

.login-reg .reg-google a {
  border: 1px solid #eeecf7;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-reg .reg-google a i {
  margin-right: 10px;
}

.login-reg .or {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.login-reg .or::after,
.login-reg .or::before {
  position: absolute;
  content: "";
  background: #eeecf7;
  height: 1px;
  width: 38%;
}

.login-reg .or::after {
  left: 0;
}

.login-reg .or::before {
  right: 0;
}

.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  overflow: visible;
  background: url(../public/assets/images/preloader.gif) no-repeat center center;
  background-size: 30%;
  background-color: #f8f8f7;
}

@media (max-width: 1199px) {
  .login-reg .sec-img {
    width: 70%;
    margin: auto;
    margin-top: 30px;
  }
  .login-reg .sec-img img {
    max-width: 100%;
  }
  .login-reg {
    margin-top: 50px;
  }

  .preloader {
    background-size: 30%;
  }
}

@media (max-width: 991px) {
  .login-reg {
    margin-top: 80px;
  }

  .preloader {
    background-size: 40%;
  }
}

@media (max-width: 480px) {
  .preloader {
    background-size: 65%;
  }
}
